import * as React from 'react';
import { Fragment, useState, useEffect } from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { Dialog, Transition } from '@headlessui/react';
import { IoMdClose } from 'react-icons/io';
import { AiFillEyeInvisible, AiFillEye } from 'react-icons/ai';
import Resizer from 'react-image-file-resizer';
import './styles.css';
import LayoutPrivate from '@components/layouts/LayoutPrivate';
import { useAuth } from '@app/contexts/MainAppContext';

const initialformData = Object.freeze({
  avatar: null,
  display_name: '',
  name: '',
  phone_number: '',
  email: '',
  password: '',
  new_password: '',
  old_password: '',
  password_confirmation: '',
});

const filterFormData = (obj) => {
  let data = {};
  const keys = ['avatar', 'display_name', 'name', 'phone_number', 'email', 'new_password'];

  for (let key in obj) {
    if (keys.includes(key)) {
      data[key] = obj[key];
    }
  }
  return data;
};

const AccountPage = () => {
  const { userData, updateUser, isUserInformationLoading } = useAuth();
  const [formData, setFormData] = useState(initialformData);
  const [formStatus, setFormStatus] = useState('standby');
  const [avatarStatus, setAvatarStatus] = useState('standby');
  const [isOpen, setIsOpen] = useState(false);
  const [isNewPasswordShown, setNewPasswordShown] = useState(false);
  const [isNewConfirmPasswordShown, setNewConfirmPasswordShown] = useState(false);
  const hiddenFileInput = React.useRef(null);

  const togglePasswordNew = () => {
    setNewPasswordShown(!isNewPasswordShown);
  };

  const togglePasswordConfirmNew = () => {
    setNewConfirmPasswordShown(!isNewConfirmPasswordShown);
  };

  const sendRequest = async (successMsg, errorMsg) => {
    try {
      const nData = { ...formData };
      nData.avatar = nData?.avatarFile ?? nData.avatar;
      const data = filterFormData(nData);
      await updateUser(data);
      alert(successMsg);
    } catch (error) {
      let msg = errorMsg;
      if (error?.msgs instanceof Object) {
        msg = error.msgs[Object.keys(error.msgs)[0]];
      }
      alert(msg);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (formStatus === 'loading') return;
    setFormStatus('loading');
    await sendRequest('Profile updated successful', 'Profile update failed');
    setFormStatus('styandby');
  };

  const updatePassword = async (e) => {
    e.preventDefault();
    if (formStatus === 'loading') return;
    if (formData.new_password !== formData.new_password_confirm) {
      alert("Password don't match");
      return;
    }

    setFormStatus('loading');
    await sendRequest('Password updated successful', 'Password update failed');
    setFormStatus('styandby');
  };

  const updateAvatar = async (e) => {
    e.preventDefault();
    if (avatarStatus === 'loading') return;

    if (formData.display_name == '' || formData.name == '' || formData.email == '') {
      alert('Please select a picture fill up fields');
      return;
    }

    setAvatarStatus('loading');
    await sendRequest('Profile picture updated successful', 'Profile picture update failed');
    setAvatarStatus('styandby');
  };

  const handleChange = (e) => {
    console.log(e.target.name, 'name');
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    console.log('1 userData', userData, 'isUserInformationLoading', isUserInformationLoading);
    if (userData && !isUserInformationLoading) {
      setFormData(userData);
    }
  }, [userData, isUserInformationLoading]);

  const handleChangeImage = (event) => {
    hiddenFileInput.current.click();
  };

  const handleChangeProfilePic = async (event) => {
    event.preventDefault();

    try {
      const file = event.target.files[0];
      const imageTry = await resizeMainImgFile(file);
      setFormData({
        ...formData,
        avatarFile: file,
        avatar: imageTry,
      });
    } catch (err) {
      console.log(err);
    }
  };

  const resizeMainImgFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        800,
        800,
        'JPEG',
        90,
        0,
        (uri) => {
          resolve(uri);
        },
        'base64'
      );
    });

  function closeModal() {
    setIsOpen(false);
  }

  function openModal() {
    setIsOpen(true);
  }

  return (
    <LayoutPrivate pageTitle="Account">
      <section className="section-secondary relative w-full h-full bg-black">
        <div className="max-w-[1000px] mx-auto grid grid-cols-1 pt-10 xl:px-20 px-5">
          <div className="pt-10 pb-5 xl:mb-20 mb-10 mt-5 xl:px-10 px-5 bg-white rounded-xl text-grey">
            <div className="secondary-page-content content-account">
              <div className="justify-items-center col-span-12 bg-white rounded-xl">
                <div className="xl:grid grid-cols-12">
                  <div className="col-span-12 xl:px-8 xl:text-left text-center">
                    <h1 className="profile-title font-rockwell-bold font-bold mb-5">Profile</h1>
                  </div>
                  <div className="avatar xl:block xl:flex-start flex flex-center align-items-center flex-col col-span-12 pb-10 px-8">
                    {formData.avatar ? (
                      <div className="inline-block align-middle rounded-full overflow-hidden">
                        <img
                          src={formData.avatar}
                          formats={['auto', 'webp', 'avif']}
                          width={'112.5px'}
                          height={'112.5px'}
                          alt="avatar image"
                          className="profile-image cursor-pointer"
                          onClick={handleChangeImage}
                          placeholder="blurred"
                        />
                        <input type="file" ref={hiddenFileInput} style={{ display: 'none' }} onChange={handleChangeProfilePic} />
                      </div>
                    ) : (
                      <>
                        <StaticImage
                          src={'../../images/account-avatar-img.png'}
                          formats={['auto', 'webp', 'avif']}
                          width={112.5}
                          height={112.5}
                          alt="avatar image"
                          className="profile-image cursor-pointer"
                          onClick={handleChangeImage}
                        />
                        <input type="file" ref={hiddenFileInput} style={{ display: 'none' }} onChange={handleChangeProfilePic} />
                      </>
                    )}
                    <button
                      className="profile-button font-opensans xl:px-8 py-3 px-4 xl:mt-7 mt-7 xl:ml-10 xl:mr-3 mx-auto xl:w-auto rounded-lg"
                      disabled={avatarStatus === 'loading'}
                      onClick={updateAvatar}>
                      {avatarStatus === 'loading' ? 'Updating' : 'Update'}
                    </button>
                  </div>
                </div>

                <form id="account-form" className="w-full col-span-12">
                  <div className="xl:grid grid-cols-12 gap-x-8 gap-y-4 pt-10 xl:px-10 pb-10">
                    <div className="xl:col-span-12 col-span-12">
                      <input
                        placeholder="Display Name"
                        id="d-name"
                        name="display_name"
                        type="text"
                        className="border-b-2 w-full h-12 mb-3 focus:outline-none focus:shadow-none bg-transparent"
                        required
                        onChange={handleChange}
                        value={formData.display_name}
                      />
                    </div>
                    <div className="xl:col-span-12 col-span-12">
                      <input
                        placeholder="Full Name"
                        id="f-name"
                        type="text"
                        name="name"
                        className="border-b-2 w-full h-12 mb-3 focus:outline-none focus:shadow-none bg-transparent"
                        required
                        onChange={handleChange}
                        value={formData.name}
                      />
                    </div>
                    <div className="xl:col-span-12 col-span-12">
                      <input
                        placeholder="Email Address"
                        id="email-add"
                        type="email"
                        name="email"
                        className="border-b-2 w-full h-12 mb-3 focus:outline-none focus:shadow-none bg-transparent"
                        required
                        onChange={handleChange}
                        value={formData.email}
                      />
                    </div>
                    <div className="xl:col-span-12 col-span-12">
                      <input
                        placeholder="Phone Number"
                        id="telephone"
                        type="phone"
                        name="phone_number"
                        className="border-b-2 w-full h-12 mb-3 focus:outline-none focus:shadow-none bg-transparent"
                        required
                        onChange={handleChange}
                        value={formData.phone_number}
                      />
                    </div>
                  </div>
                  <div className="ml-10 mr-10">
                    <button
                      className="button-profile font-opensans full-width text-center pt-2 pb-2 text-white color-white uppercase btn-round"
                      disabled={formStatus === 'loading'}
                      onClick={handleSubmit}>
                      {formStatus === 'loading' ? 'Saving Changes' : 'Save Changes'}
                    </button>
                  </div>
                  <div className="ml-10">
                    <div className="xl:col-span-6 col-span-12 xl:text-left text-left mt-4 xl:mb-0 mb-3">
                      <p className="underline rounded-md font-opensans font-bold" onClick={openModal}>
                        Reset password
                      </p>
                    </div>
                  </div>
                </form>
              </div>
              {isOpen && (
                <Transition appear show={isOpen} as={Fragment}>
                  <Dialog as="div" className="fixed inset-0 z-10 overflow-y-auto" onClose={closeModal}>
                    <div className="min-h-screen px-4 text-center">
                      <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0">
                        <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />
                      </Transition.Child>

                      {/* This element is to trick the browser into centering the modal contents. */}
                      <span className="inline-block h-screen align-middle" aria-hidden="true">
                        &#8203;
                      </span>

                      <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 scale-95"
                        enterTo="opacity-100 scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95">
                        <div className="inline-block w-full max-w-xl py-10 xl:px-12 px-5 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                          <div>
                            <IoMdClose className="ml-auto w-5 h-5 text-black cursor-pointer" onClick={closeModal} />
                          </div>
                          <div className="mt-2">
                            <form className="w-full" onSubmit={updatePassword}>
                              <div className="grid grid-cols-12">
                                <div className="col-span-12 pt-5">
                                  <label htmlFor="old-pass" className="font-bold">
                                    Old Password
                                  </label>
                                  <input
                                    id="old-pass"
                                    name="old_password"
                                    type="password"
                                    className="rounded-md border-black border w-full h-12 px-5 mb-3 focus:outline-none focus:shadow-none pb-1 bg-transparent"
                                    required
                                    onChange={handleChange}
                                  />
                                </div>
                                <div className="col-span-12 relative">
                                  <label htmlFor="new-pass" className="font-bold">
                                    New Password
                                  </label>
                                  <input
                                    id="new-pass"
                                    type={isNewPasswordShown ? 'text' : 'password'}
                                    name="new_password"
                                    className="rounded-md border-black border w-full h-12 px-5 mb-3 focus:outline-none focus:shadow-none pb-1 bg-transparent"
                                    required
                                    onChange={handleChange}
                                  />
                                  <div className="absolute top-[40px] right-[30px]">
                                    {isNewPasswordShown ? (
                                      <AiFillEye onClick={togglePasswordNew} className="show-pass-btn" />
                                    ) : (
                                      <AiFillEyeInvisible onClick={togglePasswordNew} className="show-pass-btn" />
                                    )}
                                  </div>
                                </div>
                                <div className="col-span-12 pb-5 relative">
                                  <label htmlFor="confirm-pass" className="font-bold">
                                    Confirm New Password
                                  </label>
                                  <input
                                    id="confirm-pass"
                                    type={isNewConfirmPasswordShown ? 'text' : 'password'}
                                    name="new_password_confirm"
                                    className="rounded-md border-black border w-full h-12 px-5 mb-3 focus:outline-none focus:shadow-none pb-1 bg-transparent"
                                    required
                                    onChange={handleChange}
                                  />

                                  <div className="absolute top-[40px] right-[30px]">
                                    {isNewConfirmPasswordShown ? (
                                      <AiFillEye onClick={togglePasswordConfirmNew} className="show-pass-btn" />
                                    ) : (
                                      <AiFillEyeInvisible onClick={togglePasswordConfirmNew} className="show-pass-btn" />
                                    )}
                                  </div>
                                </div>
                              </div>
                              <div className="xl:col-span-12 text-center mt-3">
                                <button className="uppercase border-orange-150 rounded-md border-4 text-brown-50 font-rokkit text-xl  px-4 py-3 font-bold bg-white-rock  shadow-inner2 hover:bg-limed-oak-550 transition-all duration-500">
                                  Reset Password
                                </button>
                              </div>
                            </form>
                          </div>
                        </div>
                      </Transition.Child>
                    </div>
                  </Dialog>
                </Transition>
              )}
            </div>
          </div>
        </div>
      </section>
    </LayoutPrivate>
  );
};
export default AccountPage;
